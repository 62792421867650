import { Box, Button } from "@jewlr/storybook/core"
import { useId } from "@react-aria/utils"
import SlickSlider from "nuka-carousel"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import styled from "styled-components"

const StyledSlider = styled(Box)`
  height: 100%;
  position: relative;
  width: 100%;
  .slider-control-centerright,
  .slider-control-centerleft {
    flex: 0 0 auto;
    height: 100%;
    position: relative;
  }

  .slider-control-centerright {
    margin-right: ${(props) =>
      props.nextPlacement?._ ||
      (typeof props.nextPlacement === "string" && props.nextPlacement) ||
      "8px"};
    ${(props) =>
      props.nextPlacement?.tablet &&
      props.theme.mediaQueries.tablet`
        margin-right: ${props.nextPlacement.tablet};
    `}
    ${(props) =>
      props.nextPlacement?.desktop &&
      props.theme.mediaQueries.desktop`
        margin-right: ${props.nextPlacement.desktop};
    `}
  }

  .slider-control-centerleft {
    margin-left: ${(props) =>
      props.prevPlacement?._ ||
      (typeof props.prevPlacement === "string" && props.prevPlacement) ||
      "8px"};
    ${(props) =>
      props.prevPlacement?.tablet &&
      props.theme.mediaQueries.tablet`
        margin-left: ${props.prevPlacement.tablet};
    `}
    ${(props) =>
      props.prevPlacement?.desktop &&
      props.theme.mediaQueries.desktop`
        margin-left: ${props.prevPlacement.desktop};
    `}
  }

  button[aria-label="next"],
  button[aria-label="previous"] {
    background: transparent !important;
    padding: 0 !important;
    position: relative;
    top: ${(props) =>
      props.arrowTop?._ ||
      (typeof props.arrowTop === "string" && props.arrowTop) ||
      "50%"};
    ${(props) =>
      props.arrowTop?.tablet &&
      props.theme.mediaQueries.tablet`
      top: ${props.arrowTop.tablet};
    `}
    ${(props) =>
      props.arrowTop?.desktop &&
      props.theme.mediaQueries.desktop`
      top: ${props.arrowTop.desktop};
    `}
  }

  button[aria-label="next"] {
    right: 0;
    transform: translateY(-50%) scaleX(-1);
  }

  button[aria-label="previous"] {
    left: 0;
    transform: translateY(-50%);
  }

  button[aria-label="next"]:disabled,
  button[aria-label="previous"]:disabled {
    display: none;
  }

  .slider-control-bottomcenter {
    margin-bottom: -32px;
  }

  .slider-control-bottomcenter button {
    fill: ${(props) => props.theme.colors.grey[20]} !important;
    opacity: 1 !important;
    padding: 0 5px;
  }

  .slider-control-bottomcenter button.active {
    fill: ${(props) => props.theme.colors.grey[50]} !important;
  }

  .slider-control-bottomcenter button:focus {
    outline: ${(props) => props.theme.colors.primary} solid 2px;
    outline-offset: 2px;
  }

  .slider-control-bottomcenter button svg {
    height: 10px;
    width: 10px;
  }
`
const SkipButton = styled(Button).attrs({
  variant: "text",
})`
  background-color: white;
  color: black;
  font-size: 14px;
  left: 50%;
  line-height: 16px;
  opacity: 0;
  padding: 6px;
  position: absolute;
  text-decoration: underline;
  top: -22px;
  transform: translateX(-50%);
  z-index: -999;
  &:focus {
    opacity: 1;
    z-index: 999;
  }
`
const Slider = React.forwardRef(
  (
    { arrowTop, className, nextPlacement, plugin, prevPlacement, ...props },
    ref
  ) => {
    const carouselId = useId()
    const skippedRef = useRef()

    const { arrows, dots, infinite, ...rest } = props
    const legacySettings = {
      ...(arrows === false && {
        renderCenterLeftControls: null,
        renderCenterRightControls: null,
      }),
      ...(dots === false && {
        renderBottomCenterControls: null,
      }),
      ...(typeof infinite !== "undefined" && {
        wrapAround: infinite,
      }),
    }
    const sliderSettings = {
      tabbed: false,
      wrapAround: true,
      ...legacySettings,
      ...rest,
    }

    if (!sliderSettings.frameAriaLabel && sl.config.env !== "production") {
      console.warn(`Missing frameAriaLabel for Slider with id: ${carouselId}`)
    }

    const handleSkipCarousel = (e) => {
      skippedRef.current?.focus()
      e.preventDefault()
      e.stopPropagation()
    }

    return (
      <StyledSlider
        arrowTop={arrowTop}
        className={className}
        nextPlacement={nextPlacement}
        prevPlacement={prevPlacement}
      >
        <SkipButton onClick={handleSkipCarousel} tabIndex="0">
          Skip {sliderSettings.frameAriaLabel || "Carousel"}
        </SkipButton>
        <SlickSlider
          carouselId={carouselId}
          enableKeyboardControls
          ref={ref}
          {...sliderSettings}
        />
        {plugin}
        <span ref={skippedRef} tabIndex="-1" />
      </StyledSlider>
    )
  }
)

Slider.displayName = "Slider"

Slider.propTypes = {
  arrowTop: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  arrows: PropTypes.bool,
  className: PropTypes.string,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  nextPlacement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  plugin: PropTypes.node,
  prevPlacement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default Slider
